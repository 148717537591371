import BubblyData from './bubbly.json';
import { fonts, upsideDownTable } from './Fonts';

export type annoyer = (input: string) => string;

const BUBBLE_LOOKUP = BubblyData as { [key: string]: string };

export const illiterate = (input: string): string => {
    const allLowerCase: string = input.toLowerCase();
    const punctuationRemoved = allLowerCase.replace(/[.,'-]+/g, '');
    const havesRemoved = punctuationRemoved.replace(/((sh|c|w)ould )have/g, '$1of');
    const yousReplaced = havesRemoved.replace(/(you)(r?)(\s|$)/g, 'u$2$3');
    const toosReplaced = yousReplaced.replace(/(too?)(\s|$)/g, '2$2');
    const aresReplaced = toosReplaced.replace(/are(\s|$)/g, 'r$1');
    const forsReplaced = aresReplaced.replace(/for(\s|$)/g, '4$1');
    const thatIsReplaced = forsReplaced.replace(/that is(\s|$)/g, 'thats$1');
    return thatIsReplaced;
};

export const upsideDown = (input: string): string => {
    return input
        .split('')
        .reverse()
        .map((ch) => upsideDownTable[ch] || ch)
        .join('');
};

const fontstitute = (font: { [key: string]: string }, input: string): string => {
    return input
        .split('')
        .map((ch) => font[ch] || ch)
        .join('');
};

export const bubbly = (input: string) => fontstitute(BUBBLE_LOOKUP, input);
export const gothic = (input: string) => fontstitute(fonts.gothic, input);
export const gothicBold = (input: string) => fontstitute(fonts.gothicBold, input);
export const mono = (input: string) => fontstitute(fonts.mono, input);
export const holey = (input: string) => fontstitute(fonts.holey, input);
export const calligraphy = (input: string) => fontstitute(fonts.calligraphy, input);
export const calligraphyBold = (input: string) => fontstitute(fonts.calligraphyBold, input);
export const circled = (input: string) => fontstitute(fonts.circled, input);
export const circledBlack = (input: string) => fontstitute(fonts.circledBlack, input);
export const tiles = (input: string) => fontstitute(fonts.tiles, input);
export const blackTiles = (input: string) => fontstitute(fonts.blackTiles, input);
export const timesBold = (input: string) => fontstitute(fonts.timesBold, input);
export const timesBoldItalic = (input: string) => fontstitute(fonts.timesBoldItalic, input);
export const arial = (input: string) => fontstitute(fonts.arial, input);
export const arialBold = (input: string) => fontstitute(fonts.arialBold, input);
export const arialItalic = (input: string) => fontstitute(fonts.arialItalic, input);
export const arialBoldItalic = (input: string) => fontstitute(fonts.arialBoldItalic, input);

const RAINBOW_FONTS = [
    fonts.gothic,
    fonts.mono,
    fonts.holey,
    fonts.calligraphy,
    fonts.circled,
    fonts.tiles,
    fonts.timesBoldItalic,
    fonts.arialBoldItalic,
    upsideDownTable,
    {},
];

export const rainbow = (input: string) => {
    let offset = 0;
    return input
        .split('')
        .map((character) => {
            offset += character.charCodeAt(0);
            const font = RAINBOW_FONTS[offset % RAINBOW_FONTS.length];
            return font[character] || character;
        })
        .join('');
};

// a tribute to Gareth Babb's freako
export const babbo = (input: string) => {
    let offset = 0;
    return input
        .split('')
        .map((character) => {
            offset += character.charCodeAt(0);
            const isOdd = offset % 2 === 1;
            return isOdd ? character.toLowerCase() : character.toUpperCase();
        })
        .join('');
};

// everything's a goddamned sentence
export const everythingIsASentence = (input: string) => {
    return input.replace(/([.,;]+)/g, '$1\n\n');
};
