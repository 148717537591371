import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function Footer(): JSX.Element {
    return (
        <>
            <div className="footer fixed-bottom bg-primary d-flex align-items-center flex-column text-white">
                <div>
                    The Annoyingifier, © 2022{' '}
                    <a className="text-white" href="https://ko-fi.com/W7W4AECBN">
                        Ashley Frieze
                    </a>{' '}
                    - always free, always annoying
                </div>
            </div>
        </>
    );
}
