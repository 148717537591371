import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Check } from 'react-bootstrap-icons';
import {
    arial,
    arialBold,
    arialBoldItalic,
    arialItalic,
    babbo,
    blackTiles,
    bubbly,
    calligraphy,
    calligraphyBold,
    circled,
    circledBlack,
    everythingIsASentence,
    gothic,
    gothicBold,
    holey,
    illiterate,
    mono,
    rainbow,
    tiles,
    timesBold,
    timesBoldItalic,
    upsideDown,
} from './Annoying';
import Footer from './Footer';
import Slider from 'react-slick';
import Quotes from './quotes.json';
import Classics from './classics.json';

const randomClassic = (): string => {
    const index = Math.floor(Math.random() * Classics.length);
    return Classics[index];
};

const ALGORITHMS = [
    { name: 'Rainbow font cavalcade', run: rainbow },
    { name: 'Everything is a goddamned sentence', run: everythingIsASentence },
    { name: 'Illiterate', run: illiterate },
    { name: 'Babbo - a mixed case dystopia', run: babbo },
    { name: 'Upside down', run: upsideDown },
    { name: 'Bubble writing', run: bubbly },
    { name: 'Scrabble tiles', run: tiles },
    { name: 'Black slate tiles', run: blackTiles },
    { name: 'Circled', run: circled },
    { name: 'Circled Black', run: circledBlack },
    { name: 'Fancy', run: holey },
    { name: 'Calligraphic', run: calligraphy },
    { name: 'Calligraphic Bold', run: calligraphyBold },
    { name: 'Gothic', run: gothic },
    { name: 'Gothic Bold', run: gothicBold },
    { name: 'Monospaced', run: mono },
    { name: 'Times Bold', run: timesBold },
    { name: 'Times Bold Italic', run: timesBoldItalic },
    { name: 'Arial', run: arial },
    { name: 'Arial Bold', run: arialBold },
    { name: 'Arial Italic', run: arialItalic },
    { name: 'Arial Bold Italic', run: arialBoldItalic },
];

function App() {
    const [inputText, setInputText] = useState<string>('');
    const [outputText, setOutputText] = useState<string>('');
    const [copied, setCopied] = useState<boolean>(false);
    const [algorithmIndex, setAlgorithmIndex] = useState<number>(0);

    const textChanged = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setInputText(event.target.value);
        recalculateOutput(event.target.value, algorithmIndex);
    };

    const recalculateOutput = (text: string, index: number): void => {
        setOutputText(ALGORITHMS[index].run(text));
        setCopied(false);
    };

    const settings = {
        arrows: false,
        autoplay: true,
        autoplaySpeed: 7000,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <>
            <div className="bg-primary d-flex">
                <Container className="bg-primary d-flex align-items-center flex-column text-white">
                    <br />
                    <h1>𝒜n𝔫o𝕪i𝒏ⓖ𝚒🄵ᴉe𝓻</h1>
                    <h3>
                        Make your social media content <em>really really</em> annoying
                    </h3>
                    <br />
                </Container>
            </div>
            <div className="bg-primary text-white">
                <Slider {...settings}>
                    {Quotes.map((quoteItem, index) => (
                        <div key={`quoteItem${index}`}>
                            <p style={{ textAlign: 'center' }}>
                                <i>&quot;{quoteItem.quote}&quot;</i>, <b>{quoteItem.name}</b>
                            </p>
                        </div>
                    ))}
                </Slider>
            </div>
            <div>
                <Container>
                    <br />
                    <Form.Label>
                        Enter your text:{' '}
                        <sup>
                            (
                            <a
                                href="#"
                                onClick={() => {
                                    const classic = randomClassic();
                                    setInputText(classic);
                                    recalculateOutput(classic, algorithmIndex);
                                }}
                            >
                                pick random
                            </a>
                            )
                        </sup>{' '}
                    </Form.Label>
                    <Form.Control as="textarea" rows={6} onChange={textChanged} value={inputText} />
                    <br />
                    <Form.Label>Choose your annoyance:</Form.Label>
                    <Form.Select
                        aria-label="Choose your annoyance"
                        onChange={(event) => {
                            const index = +event.target.value;
                            setAlgorithmIndex(index);
                            recalculateOutput(inputText, index);
                        }}
                    >
                        {ALGORITHMS.map((algorithm, index) => (
                            <option key={`algorithm${index}`} value={index}>
                                {algorithm.name}
                            </option>
                        ))}
                    </Form.Select>
                    <br />
                </Container>
            </div>
            {outputText && (
                <div className="bg-primary d-flex">
                    <Container className="bg-primary d-flex align-items-center flex-column text-white">
                        <br />
                        <h4>Now isn&apos;t this ANNOYING?</h4>
                        <br />
                        {outputText.split('\n').map((row, index) => (
                            <p key={`row${index}`} className="lead">
                                {row}
                            </p>
                        ))}
                        <p>
                            <CopyToClipboard text={outputText} onCopy={() => setCopied(true)}>
                                <Button variant="info">Copy {copied && <Check />}</Button>
                            </CopyToClipboard>
                            &nbsp;&nbsp;
                            <Button
                                variant="info"
                                onClick={() => (window.location.href = 'https://ko-fi.com/W7W4AECBN')}
                            >
                                Wow, that IS annoying
                            </Button>
                        </p>
                        <br />
                        <br />
                    </Container>
                </div>
            )}
            <Footer />
        </>
    );
}

export default App;
